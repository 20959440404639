<template>
  <list-container-widget
    id="locations-list"
    :title="widgetName"
    :app-id="app.id"
    class="d-flex"
  >
    <template #actions>
      <ViewAllButton item-type="map" :isFullScreen=true />
    </template> 
    <b-button variant="link" @click="isLegendModalVisible = true" class="p-0 mb-1">
      <feather-icon
        icon="MapPinIcon"
        size="18"
        class="text-primary share-icon mr-25"
        role="button"
      />
      <span class="text-primary">{{$t('maps.see-legend')}}</span>
    </b-button>
    <b-modal
      :title="$t('maps.legend')"
      v-model="isLegendModalVisible"
      size="sm"
      :ok-title="$t('form.actions.ok')"
      ok-only
    >
      <span v-for="(ico, index) in icons" :key="index" class="mr-50 mt-25 d-flex">
        <p class="font-weight-bold mb-0 d-flex">
          <img :src="ico.image" class="b-avatar mb-1 badge-light-secondary rounded-circle p-25 mr-25" style="height: 25px; width: 25px"/>
          {{ ico.name }} 
        </p>
      </span>
    </b-modal>
    <locations-map
      :item-key="currentCollectiveKey"
      type="collective"
      all
      is-widget
    />
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import LocationsMap from "@/views/apps/locations/components/LocationsMap.vue";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import { LocationsPinImagesForApps, AppsWithPins } from '@/@core/constants/LocationPins';

export default {
  name: "LocationsMapWidget",
  components: {
    WidgetActions,
    ListContainerWidget,
    LocationsMap,    
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isSmall: false,
      isLegendModalVisible: false,
      icons: [
        { 
          image: LocationsPinImagesForApps.community, 
          name: this.$t('backoffice.discounts.model-types.community') 
        },
      ],
    };
  },
  computed: {
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key;
    },
    enabledApps() {
      return this.$store.getters.installedApps.data?.map(
        (item) => item.addonSingular
      );
    },
    enabledOrganizationApp() {
      return this.enabledApps?.filter(item=> item === 'organization').length > 0;
    },
    enabledMembersApp() {
      return this.enabledApps?.filter(item=> item === 'member').length > 0;
    },
    enabledEventsApp() {
      return this.enabledApps?.filter(item=> item === 'event').length > 0;
    },
    enabledContactsApp() {
      return this.enabledApps?.filter(item=> item === 'contact').length > 0;
    },
    enabledSocietiesApp() {
      return this.enabledApps?.filter(item=> item === 'society').length > 0;
    },
    enabledRoomsApp(){
      return this.enabledApps?.filter(item => item === 'room').length > 0;
    },
    enabledChallengesApp(){
      return this.enabledApps?.filter(item => item === 'challenge').length > 0;
    },
    enabledProductsApp(){
      return this.enabledApps?.filter(item => item === 'product').length > 0;
    },
    enabledServicesApp(){
      return this.enabledApps?.filter(item => item === 'service').length > 0;
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    await this.getInstalledApps();
    if (this.enabledOrganizationApp) {
      this.icons.push({ 
        image: LocationsPinImagesForApps.organization, 
        name: this.$t('organizations.title') 
      })
    }
    if (this.enabledEventsApp) {
      this.icons.push({ 
        image: LocationsPinImagesForApps.event, 
        name: this.$t('events.title') 
      })
    }
    if (this.enabledMembersApp) {
      this.icons.push({ 
        image: LocationsPinImagesForApps.member, 
        name: this.$t('members.title') 
      })
    }
    if (this.enabledSocietiesApp) {
      this.icons.push({ 
        image: LocationsPinImagesForApps.society, 
        name: this.$t('societies.title') 
      })
    }
    if (this.enabledRoomsApp) {
      this.icons.push({
          image: LocationsPinImagesForApps.room,
          name: this.$t("rooms.title"),
        })
    }
    if (this.enabledChallengesApp) {
      this.icons.push({
          image: LocationsPinImagesForApps.challenge,
          name: this.$t("challenges.title"),
        })
    }
    if (this.enabledProductsApp) {
      this.icons.push({
          image: LocationsPinImagesForApps.product,
          name: this.$t("products.title"),
        })
    }
    if (this.enabledServicesApp) {
      this.icons.push({
          image: LocationsPinImagesForApps.service,
          name: this.$t("services.title"),
        })
    }
    if (this.enabledContactsApp) {
      this.icons.push({
          image: LocationsPinImagesForApps.contact,
          name: this.$t("contacts.title"),
        })
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async getInstalledApps() {
      await this.$store.dispatch("getInstalledApps");
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
  },
};
</script>
